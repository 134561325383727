import PropTypes from "prop-types";
import {Text} from "@nextui-org/react";
import React from "react";
import {useNavigate} from "react-router-dom";

export function Header() {
    const navigate = useNavigate()

    return (
        <div className="flex mt-4 flex-row w-full items-center justify-center">
            <Text onClick={() => navigate("/")} color='$white' size={'$7xl'} className='font-bold cursor-pointer'><span className='text-blue-500'>Z</span>nap.</Text>
        </div>
    );
}

Header.defaultProps = {
    brandName: "Znap",
};

Header.propTypes = {
    brandName: PropTypes.string,

};

Header.displayName = "/src//layout/footer.jsx";

export default Header;
