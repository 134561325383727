import PropTypes from "prop-types";
import {Text} from "@nextui-org/react";

export function Footer({brandName}) {
    const year = new Date().getFullYear();

    return (
        <footer className="flex flex-row w-full py-2 items-center justify-center bg-[#141414]">
            <Text b color='$white' size={'$sm'}>{brandName} &copy; {year}</Text>
        </footer>
    );
}

Footer.defaultProps = {
    brandName: "Znap",
};

Footer.propTypes = {
    brandName: PropTypes.string,

};

Footer.displayName = "/src//layout/footer.jsx";

export default Footer;
